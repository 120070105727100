import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Popup from 'reactjs-popup';

import Input from 'ui/Formik/Input';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import { useDispatch, useSelector } from 'react-redux';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';

import { api } from 'services';
import {
	createWalletAddressRequest,
	updateWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { IAddWalletsValue, IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const AddWalletAddress: FC<IPropsPopUp> = ({ open, closeModal, walletId, asset_id, create }) => {
	const dispatch = useDispatch();
	const cryptoCurrency = useSelector(getCryptoCurrencyData);
	const wallets = useSelector(getWalletsList);
	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);

	const [selectedNetwork, setSelectedNetwork] = useState<any>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [currencySelect, setCurrencySelect] = useState<ICurrencySelectItem[] | null>(null);

	const [valid, setValid] = useState(false);
	const validateWallet = async (address: string) => {
		const searchCode = selectedNetwork?.code;
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const result = wallet[0].asset.chains.find((obj) => obj.code === searchCode);
		await api.wallets
			.validateAddress({
				address,
				asset_id: selectedNetwork?.pivot?.asset_id || result?.pivot?.asset_id,
				chain_id: selectedNetwork?.pivot?.chain_id || result?.pivot?.chain_id,
			})
			.then(() => setValid(true))
			.catch(() => setValid(true)); // .catch(() => setValid(false)); - на время отключаем проверку
	};
	const validationSchema = yup.object().shape({
		nickname: yup
			.string()
			.required(notificationsInfoFields.nickname.required)
			.matches(/^[\w-,.@ '"`*&?%$!+#№^/()]*$/, notificationsInfoFields.nickname.matches)
			.min(1, notificationsInfoFields.nickname.min)
			.max(160, notificationsInfoFields.nickname.max),
		currency: yup.string().required(notificationsInfoFields.currency.required),
		address: yup
			.string()
			.min(34, notificationsInfoFields.address.min) // Add a custom message for min length
			.max(90, notificationsInfoFields.address.max) // Add a custom message for max length
			.required(notificationsInfoFields.address.required),
		// .matches(/^[A-Za-z0-9]*$/, notificationsInfoFields.address.matches), //на время отключаем проверку
		network: yup.string().required(notificationsInfoFields.network.required),
	});
	const balId = wallets
		?.find((el) => el.address.find((address) => address.id === walletId))
		?.address.find((item) => item.id === walletId)?.balance_id;

	const initialValues = {
		nickname:
			(!create &&
				wallets
					?.find((el) => el.address.find((address) => address.id === walletId))
					?.address.find((item) => item.id === walletId)?.nickname) ||
			'',
		currency: '',
		address:
			(!create &&
				wallets
					?.find((el) => el.address.find((address) => address.id === walletId))
					?.address.find((item) => item.id === walletId)?.address) ||
			'',
		network: '',
	};

	useEffect(() => {
		setCurrencySelect(
			cryptoCurrency.filter(
				(currencyItem) =>
					currencyItem.depositable || currencyItem.exchangeable || currencyItem.withdrawable,
			),
		);
	}, [cryptoCurrency]);

	useEffect(() => {
		if (walletId && !asset_id && initialValues.nickname) {
			const wallet = wallets
				?.find((el) => el.address.find((address) => address.id === walletId))
				?.address.find((item) => item.id === walletId);
			// const currency =
			// 	cryptoCurrency.find((item) => item.chains.find((chain) => chain.id === wallet?.chain_id)) ||
			// 	null;
			const currency = cryptoCurrency.find((item) => item.id === wallet?.asset_id) || null;
			setSelectedCoin(currency);
			setSelectedNetwork(wallet?.chain || null);
		} else {
			setSelectedNetwork(null);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [walletId, asset_id, wallets]);
	const createWalletAddress = (values: { address: string; nickname: string }) => {
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		if (walletId && !asset_id) {
			const searchCode = selectedNetwork?.code;

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const result = wallet[0].asset.chains.find((obj) => obj.code === searchCode);
			dispatch(
				updateWalletAddressRequest({
					address: values.address,
					asset_id: selectedNetwork?.pivot?.asset_id || result?.pivot?.asset_id,
					chain_id: selectedNetwork?.pivot?.chain_id || result?.pivot?.chain_id,
					balance_id: wallet?.[0].id || 0,
					id: walletId,
					nickname: values.nickname,
				}),
			);
		} else {
			dispatch(
				createWalletAddressRequest({
					...values,
					...selectedNetwork?.pivot,
					balance_id: wallet?.[0].id,
				}),
			);
			localStorage.asset = JSON.stringify({ asset_id: walletId });
		}
	};

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(getWalletsRequest());
	}, [dispatch]);

	useEffect(() => {
		setResetCustomSelect(true);
	}, [selectedCoin]);
	useEffect(() => {
		if (asset_id && cryptoCurrency) {
			const currentAsset = cryptoCurrency.find((asset) => asset.id === asset_id) || null;
			setSelectedCoin(currentAsset);
		}
	}, [asset_id, cryptoCurrency]);

	return (
		<Popup open={open} onClose={() => closeModal()}>
			<div className="modal">
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">
							{walletId && !asset_id ? 'Update Wallet Address' : 'Add Wallet Address'}
						</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={async (
							values: IAddWalletsValue,
							{ setFieldError, setSubmitting, resetForm },
						) => {
							await validateWallet(values.address);
							if (valid) {
								// на время отключаем проверку.Когда включат проверку поставить !valid
								// setFieldError(
								// 	'address',
								// 	'Wallet address does not match the selected Cryptocurrency and/or Network.',
								// );  на время отключаем проверку
							} else {
								closeModal();
								createWalletAddress(values);
								resetForm();
								setValid(false);
							}
							setSubmitting(false);
						}}
					>
						{({ isSubmitting, errors }) => (
							<Form className="form">
								<div className="popup-body">
									<Field
										title="Wallet Nickname"
										type="text"
										placeholder="Enter Wallet Nickname"
										name="nickname"
										required
										component={Input}
									/>

									<Field
										type="text"
										title="Cryptocurrency"
										placeholder="Choose an Address here"
										dropdownTitle="Select Cryptocurrency"
										name="currency"
										required
										component={CurrencySelect}
										arr={currencySelect}
										onChange={setSelectedCoin}
										activeValue={selectedCoin || undefined}
									/>

									<Field
										type="text"
										title="Network"
										placeholder="Select Network"
										dropdownTitle="Select Network"
										name="network"
										required
										component={CurrencySelect}
										activeValue={selectedNetwork}
										// arr={selectedCoin?.chains?.filter((chain) => chain.id !== 7)}
										arr={
											selectedCoin && selectedCoin.code === 'usdc'
												? selectedCoin?.chains?.filter((chain) => chain.id !== 5)
												: selectedCoin?.chains?.filter((chain) => chain.id !== 7)
										}
										onChange={setSelectedNetwork}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
									/>

									<Field
										title="Wallet Address"
										type="text"
										placeholder="Enter Wallet Address"
										name="address"
										required
										component={Input}
									/>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--type2">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={isSubmitting}
										>
											{walletId && !asset_id ? 'Update Wallet Address' : 'Confirm'}
										</button>
										<button
											type="button"
											onClick={closeModal}
											className="btn btn-outline btn-secondary"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default AddWalletAddress;
