import React, { FC, useCallback, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeData, getTradeLayers, getTradeLayersLog } from 'redux/reducers/trade/selectors';
import {
	getHistoryLoading,
	getTradeInfo,
	getTradeInfoModal,
	getTradeLoading,
} from 'redux/reducers/transactions/selectors';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { getTradeQuoteRequest, makeTradeRequest } from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { navList } from 'routes/routesList';
import { IPopUp } from '../types';
import { feeRequest, spreadFeeRequest } from '../../../redux/reducers/deposits/reducer';
import { getFee, getSpreadFee } from '../../../redux/reducers/deposits/selectors';
import { layersTranspile } from '../../../services/utils/layersTranspile';
import { getPopUp } from '../../../redux/reducers/popUp/selectors';
import { ILayersData, layersFilter } from '../../../services/utils/layersFilter';
import { getPriceLevel } from '../../../components/Trade/getPriceLevel';
import { calculatePercent } from '../../../components/Trade/calculatePercent';
import { toDinamicDecimals } from '../../../services/utils/numbers';
import { formatValue } from '../../../components/Trade/formatValue';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

type TTimeout = ReturnType<typeof setTimeout>;
let timeout: TTimeout;

const ConfirmTrade: FC<IPopUp> = ({ open, closeModal, data }) => {
	const dispatch = useDispatch();
	const currency = useSelector(getCurrencyData);
	const tradeInfo = useSelector(getTradeInfo);
	const tradeInfoModal = useSelector(getTradeInfoModal);
	const trade = useSelector(getTradeData);
	const historyLoading = useSelector(getHistoryLoading);
	const tradeLoading = useSelector(getTradeLoading);
	const feeNumber = useSelector(getFee);
	const foundFromCurrency = currency.find((el) => el.id === Number(trade.from_asset_id));
	const foundToCurrency = currency.find((el) => el.id === Number(trade.to_asset_id));
	const layers = useSelector(getTradeLayers);
	const [tradeBuyLayers, setTradeBuyLayers] = useState<Record<string, any> | null>(null);
	const [tradeSellLayers, setTradeSellLayers] = useState<Record<string, any> | null>(null);
	const [tradePairState, setTradePairState] = useState('');
	const [calculatedPriceState, setCalculatedPriceState] = useState<string | number | null>(null);
	const [calculatedValueState, setCalculatedValueState] = useState<string | number | null>(null);
	const [calculatedValueFinalState, setCalculatedValueFinalState] = useState<
		string | number | null
	>(null);
	const [reversed, setReversed] = useState(false);
	const [fromIsFiat, setFromIsFiat] = useState(false);
	const [toIsFiat, setToIsFiat] = useState(false);
	const [mainPrice, setMainPrice] = useState<string | number>(0);
	const [updatePriceAnim, setUpdatePriceAnim] = useState(false);
	const fee = useSelector(getFee);
	const spreadFee = useSelector(getSpreadFee);
	const currentPopup = useSelector(getPopUp);
	const layersLog = useSelector(getTradeLayersLog);

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const percentfee = tradeInfo?.trade_fee_percent
		? (Number(trade.from_asset_count) * Number(tradeInfo?.trade_fee_percent)) / 100
		: 0;
	const mainFee =
		percentfee && tradeInfo && percentfee > Number(tradeInfo?.trade_fee_fixed)
			? percentfee
			: tradeInfo?.trade_fee_fixed || 0;

	const receivWithoutFee = Number(tradeInfo?.price) * Number(trade.from_asset_count);
	const from = trade?.from_asset_count || 0;
	const newReceive = Number(feeNumber) / (Number(from) / 100);
	const finalRecive =
		(newReceive / 100) * receivWithoutFee === 0
			? receivWithoutFee
			: (newReceive / 100) * receivWithoutFee;
	const finalReciveView =
		Number(receivWithoutFee) - finalRecive === 0
			? finalRecive
			: Number(receivWithoutFee) - finalRecive;

	const autoGetTradeExchangeRate = (assetFrom: string, assetTo: string) => {
		// clearTimeout(timeout);
		const params = { from_asset_id: assetFrom, to_asset_id: assetTo };
		//---------------------------------------
		const fiatArrCodeFunc = (arr: any, type: string) => {
			return arr.filter((obj: any) => obj.type === type);
		};

		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являються криптвой
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используються значит true
		const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		dispatch(
			getTradeQuoteRequest({
				...params,
				quantity: Number((Number(quantityToFrom()) - mainFee).toFixed(5)),
			}),
		);
		// timeout = setTimeout(() => autoGetTradeExchangeRate(assetFrom, assetTo), 10000);
	};
	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	const confirmHandler = () => {
		//---------------------------------------
		const fiatArrCodeFunc = (arr: any, type: string) => {
			return arr.filter((obj: any) => obj.type === type);
		};
		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являються криптой
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};

		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используются значит true
		// const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		if (trade.from_asset_id !== null && trade.to_asset_id !== null) {
			const payload = {
				from_asset_id: trade.from_asset_id,
				to_asset_id: trade.to_asset_id,
				quantity: Number(trade.from_asset_count),
				crypto_quantity: Number(quantityToFrom()),
				exchange_rate: trade.exchange_rate_raw,
				exchange_rate_with_spread: Number(String(trade.main_price).replace(',', '')),
				pair_code: trade.pair_code_raw,
				sell_quantity: Number(trade.from_asset_count),
				buy_quantity: Number(trade.to_asset_count),
				last_input: trade.last_input,
				popupData: {
					sell: toFixedNumber(trade.from_asset_count || '', foundFromCurrency?.code, true),
					sellCode: foundFromCurrency?.code,
					receive: toFixedNumber(Number(calculatedValueFinalState), foundToCurrency?.code, true),
					receiveCode: foundToCurrency?.code,
					priceOneCode: reversed && fromIsFiat && !toIsFiat ? twoText : oneText,
					price: mainPrice,
					priceCode: reversed && fromIsFiat && !toIsFiat ? oneText : twoText,
					fee: toFixedNumber(Number(fee), foundFromCurrency?.code, true),
					feeCode: foundFromCurrency?.code,
					spreadFee: toFixedNumber(Number(spreadFee), foundFromCurrency?.code, true),
					spreadFeeCode: '%',
				},
			};
			dispatch(makeTradeRequest(payload));
			console.log(layersLog);
		}
		// closeModal();
	};

	useEffect(() => {
		if (trade.from_asset_id !== null && trade.to_asset_id !== null) {
			autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		}
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUpdatePriceAnim(true);
		setTimeout(() => setUpdatePriceAnim(false), 1000);
	}, [historyLoading]);

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-660">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">Confirm Trade</p>
					<p className="popup-header__title bold">
						<span className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
							{toFixedNumber(trade?.to_asset_count || '', foundToCurrency?.code, true)}{' '}
						</span>
						<span className="element---uppercase">{String(trade?.to_asset_code)}</span>
					</p>
				</div>
				<div className="popup-body">
					<div className="instant-exchange-from-to">
						<div className="instant-exchange-from-to__item">
							<p>You Sell</p>
							<span className="coin">
								<span className="coin__icon">
									{/* <img
										src={foundFromCurrency?.img_path.replace(
											'oneify-stage.corp.merehead.xyz',
											'dev1.oneify.cybro.cz',
										)}
										alt={foundFromCurrency?.code}
									/> */}
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${foundFromCurrency?.code.toLowerCase()}.svg`}
										alt={foundFromCurrency?.code}
									/>
								</span>
								<span className="coin__curr">
									<span className="coin__code">{foundFromCurrency?.code}</span>
									<span className="coin__name">{foundFromCurrency?.name}</span>
								</span>
							</span>
						</div>
						<div className="instant-exchange-from-to__item">
							<p>You Receive</p>
							<span className="coin">
								<span className="coin__icon">
									{/* <img
										src={foundToCurrency?.img_path.replace(
											'oneify-stage.corp.merehead.xyz',
											'dev1.oneify.cybro.cz',
										)}
										alt={foundToCurrency?.code}
									/> */}
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${foundToCurrency?.code.toLowerCase()}.svg`}
										alt={foundToCurrency?.code}
									/>
								</span>
								<span className="coin__curr">
									<span className="coin__code">{foundToCurrency?.code}</span>
									<span className="coin__name">{foundToCurrency?.name}</span>
								</span>
							</span>
						</div>
					</div>
					<div className="instant-exchange-confirm-list">
						<div className="instant-exchange-confirm-list__item">
							<p>You Sell:</p>
							<p className="element---uppercase text---semibold">
								{toFixedNumber(trade.from_asset_count || '', foundFromCurrency?.code, true)}{' '}
								{foundFromCurrency?.code}
							</p>
						</div>
						<div className="instant-exchange-confirm-list__item">
							<p>You Receive:</p>
							<p className="text---semibold element---uppercase">
								{toFixedNumber(trade?.to_asset_count || '', foundToCurrency?.code, true)}{' '}
								<span className="element---uppercase">{foundToCurrency?.code}</span>
							</p>
						</div>

						<div className="instant-exchange-confirm-list__item">
							<p>Price:</p>
							{trade?.reversed ? (
								<p className="text---semibold">
									1 <span className="element---uppercase">{twoText}</span> ={' '}
									{/* {roundingNumber(tradeInfo?.price_for_main_page, twoText || '')}{' '} */}
									{/* {roundingNumber(tradeInfo?.price, twoText)}{' '} */}
									{!!trade?.main_price && formatValue(trade?.main_price)}{' '}
									<span className="element---uppercase">{oneText}</span>
								</p>
							) : (
								<p className="text---semibold">
									1 <span className="element---uppercase">{oneText}</span> ={' '}
									{/* {roundingNumber(tradeInfo?.price_for_main_page, twoText || '')}{' '} */}
									{/* {roundingNumber(tradeInfo?.price, twoText)}{' '} */}
									{!!trade?.main_price && formatValue(trade?.main_price)}{' '}
									<span className="element---uppercase">{twoText}</span>
								</p>
							)}
						</div>

						<div className="instant-exchange-confirm-list__item">
							<p>Trade Fee:</p>
							<p className="text---semibold">
								{toFixedNumber(Number(trade?.trade_fee), foundFromCurrency?.code, true)}{' '}
								<span className="element---uppercase">{foundFromCurrency?.code}</span>
							</p>
						</div>
						{/* <div className="instant-exchange-confirm-list__item">
							<p>Spread Fee:</p>
							{tradeInfo && (
								<p className="text---semibold">
									{Number(spreadFee)}
									<span className="element---uppercase">%</span>
								</p>
							)}
						</div> */}
					</div>

					<div className="instant-exchange-confirm-list-note items-center mb-0">
						<p>
							<span>Please Note:</span> Price will update as required.
						</p>
					</div>
					<div className="instant-exchange-confirm-info">
						<p>
							By clicking on Confirm Trade you hereby declare that you have read, understood and
							agree to be bound by our{' '}
							<a href={navList.termsOfUse.path} onClick={closeModal}>
								Terms of Use
							</a>
							.
						</p>
					</div>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={confirmHandler}
							className={tradeLoading ? 'btn btn-primary is-loading' : 'btn btn-primary'}
							// disabled={finalReciveView <= 0 || tradeLoading}
							disabled={tradeLoading}
						>
							Confirm Trade
						</button>
						<button type="button" onClick={closeModal} className="btn btn-outline btn-secondary">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmTrade;
