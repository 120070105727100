/* eslint-disable no-restricted-syntax */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Popup from 'reactjs-popup';
import * as yup from 'yup';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { createCryptoWithdrawRequest } from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getHistoryLoading, getWithdrawalLoading } from 'redux/reducers/transactions/selectors';
import InputTotp from 'ui/Formik/InputTotp';
import IconSvg from 'ui/Svg/IconSvg';
import { IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

const ConfirmCryptoWithdrawal: FC<IPropsPopUp> = ({
	open,
	closeModal,
	openSuccess,
	amount,
	fee,
	address,
	network,
	code,
	assetName,
	asset_id,
	chain_id,
	chain,
	address_id,
	clearParentForm,
	reference,
	clientNote,
	files,
}) => {
	const dispatch = useDispatch();

	const loading = useSelector(getWithdrawalLoading);

	const transactionSubmit = (
		totp: string,
		setErrors: (
			errors: FormikErrors<{
				totp: string;
			}>,
		) => void,
	) => {
		const formData = new FormData();
		files && files.length && files.forEach((file) => formData.append(`files[]`, file));
		const requestBody = {
			asset_id: Number(asset_id),
			chain_id: Number(chain_id),
			amount: Number(amount),
			address_id: Number(address_id),
			totp,
			reference,
			client_notes: clientNote,
		};
		Object.keys(requestBody).forEach((key) => {
			const value = requestBody[key as keyof typeof requestBody];
			if (value !== undefined) {
				formData.append(key, value.toString());
			}
		});
		dispatch(
			createCryptoWithdrawRequest({
				body: formData,
				openSuccess,
				setErrors,
			}),
		);
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
	});

	return (
		<Popup open={open} onClose={closeModal} closeOnDocumentClick={false}>
			<div className="modal">
				<div className="popup popup--width-660">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">Confirm Withdrawal</p>
					</div>
					<Formik
						initialValues={{ totp: '' }}
						validationSchema={validationSchema}
						onSubmit={(value, { setSubmitting, setErrors }) => {
							setSubmitting(false);

							transactionSubmit(value.totp, setErrors);
						}}
					>
						{({ isSubmitting }) => (
							<Form className="form">
								<div className="popup-body">
									<div className="successful-info">
										<div className="successful-info__item">
											<p>Cryptocurrency:</p>
											<span>
												{code} ({assetName})
											</span>
										</div>
										<div className="successful-info__item">
											<p>Wallet Address:</p>
											<span>{address}</span>
										</div>
										<div className="successful-info__item">
											<p>Network:</p>
											<span>
												{chain?.name === chain?.code
													? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													  `${chain?.name}`
													: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													  `${chain?.name} ${chain?.code}`}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Amount Requested:</p>
											<span>
												{toFixedNumber(Number(amount), code, true)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Fee:</p>
											<span>
												{toFixedNumber(Number(fee), code, true)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Amount:</p>
											<span>
												{toFixedNumber(Number(amount) - Number(fee), code, true)} {code}
											</span>
										</div>
									</div>
									<div className="error-block">
										<div className="error-block__icon">
											<IconSvg name="error-circle" w="20" h="20" />
										</div>
										<p>
											Ensure that the Withdrawal Address and Network are correct. If in doubt,
											please send a small test withdrawal first. Withdrawals cannot be cancelled or
											reversed.
										</p>
									</div>
									<div className="amount-input">
										<Field
											title="Authentication Code"
											type="text"
											placeholder="Please enter valid Authentication Code"
											name="totp"
											required
											component={InputTotp}
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--type2">
										<button
											disabled={isSubmitting || loading}
											type="submit"
											className="btn btn-primary btn--full"
											aria-label="form-submit"
										>
											{loading && <span className="loader" />}
											Confirm Withdrawal
										</button>
										<button
											type="button"
											onClick={closeModal}
											className="btn btn-outline btn-secondary"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmCryptoWithdrawal;
