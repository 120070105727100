import { FC, useEffect, useState } from 'react';

import { capitalize } from 'services/utils/strings';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import { toMaxDecimals } from 'services/utils/numbers';
import moment from 'moment';
import NoDataIcon from 'ui/NoDataIcon/NoDataIcon';

import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList);
	useEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);

	/* useEffect(() => {
		console.log(itemFilter);
	}, [itemFilter]); */
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);

					return (
						<div key={item.id} className="tr">
							<div className="td">
								<div className="td-hidden-name">Timestamp</div>
								<div className="td__wrap">
									{item.type === 'deposit' ? (
										<>
											<div className="td__text">
												{moment.utc(item.created_at).format('YYYY-MM-DD')}
											</div>
											<div className="td__more">
												{moment.utc(item.created_at).format('HH:mm:ss')}
											</div>
										</>
									) : (
										<>
											<div className="td__text">{date}</div>
											<div className="td__more">{time}</div>
										</>
									)}
								</div>
							</div>
							{/* <div className="td">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Currency</div>
								<div className="td__wrap">
									<div className="td__text">{item?.asset?.code.toUpperCase()}</div>
									<div className="td__more">{item?.asset.name}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<div className="td__wrap">
									<div className="td__text">
										{toFixedNumber(item.gross_fee, item?.asset?.code, true)}
									</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<div className="td__wrap">
									<div className="td__text">{toFixedNumber(item.fee, item?.asset?.code, true)}</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<div className="td__wrap">
									<div className="td__text">
										{toFixedNumber(item.net_fee, item?.asset?.code, true)}
									</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Account Name</div>
								<div className="td__wrap">
									<div className="td__text">
										{item?.bank?.bank_name ? item?.bank?.bank_name : '----'}
									</div>
									{item?.bank?.account_number && (
										<>
											<div className="td__more">{item?.bank?.account_number}</div>
										</>
									)}
								</div>
							</div>
							{/* <div className="td"> */}
							{/*	<div className="td-hidden-name">Tx ID</div> */}
							{/*	<p>???</p> */}
							{/* </div> */}
							<div className="td">
								<div className="td-hidden-name">Tx ID</div>
								<div className="td__wrap">
									<div className="td__text">{item?.id}</div>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-hidden-name">Status</div>
								<div className="td__wrap">
									<div className={`td__status ${statusClassNames[item.status]}`}>
										{statusNames[item.status]}
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<NoDataIcon />
			)}
		</div>
	);
};

export default TableBody;
