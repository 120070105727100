import React, { FC, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import Select from '../index';
import { IWalletItem, IWalletSelect, IWalletSelectCustomList } from './types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const WalletSelectCustomInput: FC<IWalletItem> = (props) => {
	const {
		asset: { code, name, img_path: imgPath },
	} = props;
	return (
		<span className="coin coin--type7" style={{ display: name && code ? '' : 'none' }}>
			{imgPath && (
				<span className="coin__icon">
					<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={name} />
				</span>
			)}
			<span className="coin__text-wrap">
				<span className="coin__text upper__text">
					<span className="coin__text-more coin__text-bold">{name}</span>
					{code}
				</span>
			</span>
		</span>
	);
};
const WalletSelectCustomList: FC<IWalletSelectCustomList> = ({
	arr,
	onChange, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	activeTabVal,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	tabs,
}) => (
	<>
		{tabs ? (
			<>
				{arr && activeTabVal === 'fiat' && (
					<motion.ul
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.5 } }}
						exit={{ opacity: 0, transition: { duration: 0 } }}
					>
						{arr
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							.filter((el) => el.asset.type === 'fiat')
							.map((el) => (
								<li key={el.asset.code}>
									<button type="button" onClick={() => onChange(el)}>
										<span className="coin coin--type7">
											{el.asset.img_path && (
												<span className="coin__icon">
													<img
														src={`/img/currencies/${el.asset.code.toLowerCase()}.svg`}
														alt={el.asset.code}
													/>
												</span>
											)}
											<span className="coin__text-wrap">
												<span className="coin__text upper__text">
													<span className="coin__text-more">{el.asset.name}</span>
													{el.asset.code}
												</span>
												{/* <span className="coin__text-balance">
													<span className="coin__text-balance-title">Total Balance</span>
													<span className="coin__text-balance-value">
														{' '}
														{roundingNumber(el.balance, el.asset.code)}
													</span>
												</span> */}
											</span>
										</span>
									</button>
								</li>
							))}
					</motion.ul>
				)}
				{arr && activeTabVal === 'crypto' && (
					<motion.ul
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.5 } }}
						exit={{ opacity: 0, transition: { duration: 0 } }}
					>
						{arr
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							.filter((el) => el.asset.type !== 'fiat')
							.map((el) => (
								<li key={el.asset.code}>
									<button type="button" onClick={() => onChange(el)}>
										<span className="coin coin--type7">
											{el.asset.img_path && (
												<span className="coin__icon">
													<img
														src={`/img/currencies/${el.asset.code.toLowerCase()}.svg`}
														alt={el.asset.code}
													/>
												</span>
											)}
											<span className="coin__text-wrap">
												<span className="coin__text upper__text">
													<span className="coin__text-more">{el.asset.name}</span>
													{el.asset.code}
												</span>
												{/* <span className="coin__text-balance">
													<span className="coin__text-balance-title">Total Balance</span>
													<span className="coin__text-balance-value">
														{' '}
														{roundingNumber(el.balance, el.asset.code)}
													</span>
												</span> */}
											</span>
										</span>
									</button>
								</li>
							))}
					</motion.ul>
				)}
			</>
		) : (
			<>
				{arr && (
					<ul>
						{arr.map((el) => (
							<li key={el.asset.code}>
								<button type="button" onClick={() => onChange(el)}>
									<span className="coin coin--type3">
										{el.asset.img_path && (
											<span className="coin__icon">
												<img
													src={el.asset.img_path.replace(
														'oneify-stage.corp.merehead.xyz',
														'dev1.oneify.cybro.cz',
													)}
													alt={el.asset.code}
												/>
											</span>
										)}
										<span className="coin__text-wrap">
											<span className="coin__text upper__text coin__text---pt-0">
												{el.asset.code} <br />
												<span className="coin__text-more coin__text-more--type2">
													{el.asset.name}
												</span>
											</span>
											<span className="coin__text-balance">
												<span className="coin__text-balance-title">Total Balance</span>
												<span className="coin__text-balance-value">
													{' '}
													{toFixedNumber(el.balance, el.asset.code, true)}
												</span>
											</span>
										</span>
									</span>
								</button>
							</li>
						))}
					</ul>
				)}
			</>
		)}
	</>
);

const WalletSelect: FC<IWalletSelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	activeValue,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	tabs,
	...props
}) => {
	const initActiveWallet = useMemo(
		() => ({
			asset: {
				code: '',
				name: '',
			},
		}),
		[],
	);

	const [activeWallet, setActiveWallet] = useState<IWalletItem>(initActiveWallet);
	const [searchCurrency, setSearchCurrency] = useState<IWalletItem[]>([]);
	const [activeTabVal, setActiveTabVal] = useState('fiat');

	useEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveWallet(initActiveWallet);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveWallet, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveWallet(activeValue || initActiveWallet);
		activeValue?.asset.type === 'token'
			? setActiveTabVal('crypto')
			: setActiveTabVal(activeValue?.asset?.type || 'fiat');
		// console.log(activeValue?.asset?.type);
	}, [activeValue, initActiveWallet]);

	const onChangeHandler = (el: IWalletItem) => {
		setActiveWallet(el);
		!!onChange && onChange(el);
	};

	const customList = {
		list: (
			<WalletSelectCustomList
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				activeTabVal={activeTabVal}
				arr={searchCurrency}
				onChange={onChangeHandler}
				tabs={tabs}
			/>
		),
		activeElement: activeWallet.asset.code,
	};

	const activeTabHandler = (value: any) => {
		setActiveTabVal(value);
	};

	return (
		<Select
			{...props}
			customInput={<WalletSelectCustomInput {...activeWallet} />}
			customList={customList}
			searchField
			setSearchCurrency={setSearchCurrency}
			arr={arr}
			tabs={tabs}
			activeTab={activeTabHandler}
			activeTabVal={activeTabVal}
		/>
	);
};

export default WalletSelect;
