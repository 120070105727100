import React, { FC, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikErrors } from 'formik';
import Popup from 'reactjs-popup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createFiatWithdrawRequest } from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import InputTotp from 'ui/Formik/InputTotp';
import { getHistoryLoading, getWithdrawalLoading } from 'redux/reducers/transactions/selectors';

import IconSvg from 'ui/Svg/IconSvg';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const ConfirmFiatWithdrawal: FC<IPropsPopUp> = ({
	open,
	closeModal,
	amount,
	fee,
	indicatedAmount,
	code,
	assetName,
	bankNickname,
	asset_id,
	bank_id,
	openSuccess,
	clearParentForm,
	walletId,
	withdrawMax,
	reference,
	clientNote,
	files,
}) => {
	const dispatch = useDispatch();
	const loading = useSelector(getWithdrawalLoading);

	const transactionSubmit = (
		totp: string,
		setErrors: (
			errors: FormikErrors<{
				totp: string;
			}>,
		) => void,
	) => {
		const formData = new FormData();
		files && files.length && files.forEach((file) => formData.append(`files[]`, file));
		const requestBody = {
			asset_id: Number(asset_id),
			bank_account_id: Number(bank_id),
			indicated_amount: Number(indicatedAmount),
			totp,
			reference,
			client_notes: clientNote,
		};

		Object.keys(requestBody).forEach((key) => {
			const value = requestBody[key as keyof typeof requestBody];
			if (value !== undefined) {
				formData.append(key, value.toString());
			}
		});

		dispatch(
			createFiatWithdrawRequest({
				body: formData,
				openSuccess,
				setErrors,
				clearParentForm,
				withdrawMax,
				data: { assetName, bankNickname, amount, fee, indicatedAmount, code, walletId },
			}),
		);
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
	});

	return (
		<Popup open={open} onClose={closeModal} closeOnDocumentClick={false}>
			<div className="modal">
				<div className="popup popup--width-660">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">Confirm Withdrawal</p>
					</div>
					<Formik
						initialValues={{ totp: '' }}
						validationSchema={validationSchema}
						onSubmit={(value, { setSubmitting, setErrors }) => {
							transactionSubmit(value.totp, setErrors);
							setSubmitting(false);
						}}
					>
						{({ isSubmitting }) => (
							<Form className="form">
								<div className="popup-body">
									<div className="successful-info">
										<div className="successful-info__item">
											<p>Currency:</p>
											<span>
												{code} ({assetName})
											</span>
										</div>
										<div className="successful-info__item">
											<p>Beneficiary:</p>
											<span>{bankNickname}</span>
										</div>
										<div className="successful-info__item">
											<p>Amount Requested:</p>
											<span>
												{toFixedNumber(Number(amount), code, true)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Fee:</p>
											<span>
												{toFixedNumber(Number(fee), code, true)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Amount:</p>
											<span>
												{toFixedNumber(Number(amount) - Number(fee), code, true)} {code}
											</span>
										</div>
									</div>
									<div className="error-block">
										<div className="error-block__icon">
											<IconSvg name="error-circle" w="20" h="20" />
										</div>
										<p>
											Ensure that the above details are correct. If in doubt, please send a small
											test withdrawal first. Withdrawals cannot be cancelled or reversed.
										</p>
									</div>
									<div className="amount-input">
										<Field
											title="Authentication Code"
											type="text"
											placeholder="Please enter valid Authentication Code"
											name="totp"
											required
											component={InputTotp}
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit flex-column">
										<button
											disabled={isSubmitting || loading}
											type="submit"
											className="btn btn-primary btn--full"
										>
											{loading && <span className="loader" />}
											Confirm Withdrawal
										</button>
										<button
											type="button"
											onClick={closeModal}
											className="btn btn-outline btn-secondary"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmFiatWithdrawal;
