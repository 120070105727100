import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getWalletsCryptoList } from 'redux/reducers/wallets/selectors';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { getWalletAddresses } from 'redux/reducers/walletAddresses/selectors';
import {
	createWalletAddressRequest,
	updateWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';

import Input from 'ui/Formik/Input';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

export interface IWalletAddressForm {
	id: number | null;
	closeForm: () => void;
}

interface IInitVals {
	nickname: string;
	address: string;
	currency: any;
	network: string;
}

const WalletAddressForm: FC<IWalletAddressForm> = ({ closeForm, id }) => {
	const dispatch = useDispatch();
	const wallets = useSelector(getWalletsCryptoList);
	const walletAddresses = useSelector(getWalletAddresses);
	const cryptoCurrency = useSelector(getCryptoCurrencyData);

	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<ICurrencySelectItem | null>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [currencySelect, setCurrencySelect] = useState<ICurrencySelectItem[] | null>(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(getWalletsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCurrencySelect(
			cryptoCurrency.filter(
				(currencyItem) =>
					currencyItem.depositable || currencyItem.exchangeable || currencyItem.withdrawable,
			),
		);
	}, [cryptoCurrency]);

	const [initialInputValues, setInitialInputValues] = useState({
		nickname: '',
		address: '',
	});
	const [initVals, setInitVals] = useState<IInitVals>();

	const validationSchema = yup.object().shape({
		nickname: yup
			.string()
			.required(notificationsInfoFields.nickname.required)
			.matches(/^[\w-,.@ '"`*&?%$!+#№^/()]*$/, notificationsInfoFields.nickname.matches)
			.min(1, notificationsInfoFields.nickname.min)
			.max(160, notificationsInfoFields.nickname.max),
		address: yup
			.string()
			.required(notificationsInfoFields.address.required)
			.min(34, notificationsInfoFields.address.min)
			.max(90, notificationsInfoFields.address.max),
		// .matches(/^[A-Za-z0-9]*$/, notificationsInfoFields.address.matches), //на время отключаем проверку
		network: yup.string().required(notificationsInfoFields.network.required),
	});
	useEffect(() => {
		if (id) {
			const walletAddress = walletAddresses.filter((el) => el.id === id);
			setInitialInputValues({
				nickname: walletAddress[0].nickname,
				address: walletAddress[0].address,
			});
			const walletAddressAssetCode = walletAddress[0].asset?.code;

			const matchedCurrency = cryptoCurrency?.find(
				(el) => el.code === walletAddressAssetCode && el.chains[0].id === walletAddress[0].chain_id,
			);
			if (matchedCurrency !== undefined) {
				setSelectedCoin(matchedCurrency);
				setSelectedNetwork(matchedCurrency.chains[0]);
				setInitVals({
					nickname: walletAddress[0].nickname,
					address: walletAddress[0].address,
					currency: matchedCurrency?.code,
					network: matchedCurrency.chains[0]?.code,
				});
			}
			// conclusion, has two networks!!!
			if (walletAddress[0].chain_id === 7) {
				const currencyEth = cryptoCurrency?.filter((el) => el.chains[0].id === 4);
				setSelectedCoin(currencyEth[0]);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setSelectedNetwork(currencyEth[0].chains[1]);
				setInitVals({
					nickname: walletAddress[0].nickname,
					address: walletAddress[0].address,
					currency: currencyEth[0],
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					network: currencyEth[0].chains[1],
				});
			}
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [cryptoCurrency, walletAddresses, id]);

	const onCurrencyChange = (el: ICurrencySelectItem) => {
		setSelectedCoin(el);
		setSelectedNetwork(null);
	};

	const onSubmit = (obj: { nickname: string; address: string }) => {
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		if (wallet?.length && selectedNetwork?.pivot) {
			const requestObj = { ...obj, ...selectedNetwork.pivot, balance_id: wallet[0].id };
			id
				? dispatch(updateWalletAddressRequest({ ...requestObj, id }))
				: dispatch(createWalletAddressRequest(requestObj));
		}
		setSelectedCoin(null);
		setSelectedNetwork(null);
	};

	const handleWholeFormChange = (values: any) => {
		// console.log(values);
		if (id) {
			if (JSON.stringify(initVals) === JSON.stringify(values)) {
				setButtonDisabled(true);
			} else {
				setButtonDisabled(false);
			}
		}
	};

	return (
		<div className="WalletAddressForm">
			<div className="wallet-operations-header-wrap">
				<div className="wallet-operations-header">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button onClick={closeForm} type="button" className="btn btn--icon btn--back" />
					<p>{id ? 'Update' : 'Add'} Wallet Address</p>
				</div>
			</div>
			<Formik
				validationSchema={validationSchema}
				initialValues={initialInputValues}
				onSubmit={(obj, { resetForm, setSubmitting }) => {
					setSubmitting(false);
					resetForm();
					onSubmit(obj);
					closeForm();
				}}
				enableReinitialize
				validateOnBlur
			>
				{({ isSubmitting, isValid, dirty, values }) => (
					<>
						{handleWholeFormChange(values)}
						<Form className="form form--type2">
							<div className="form-body">
								<Field
									title="Wallet Nickname"
									type="text"
									placeholder="Enter Wallet Nickname"
									name="nickname"
									required
									component={Input}
								/>
								<div className="input">
									<Field
										type="text"
										title="Cryptocurrency"
										placeholder="Select Cryptocurrency"
										dropdownTitle="Select Cryptocurrency"
										name="currency"
										required
										component={CurrencySelect}
										arr={currencySelect}
										onChange={onCurrencyChange}
										activeValue={selectedCoin || undefined}
									/>
								</div>
								<div className="input">
									<Field
										type="text"
										title="Network"
										placeholder="Select Network"
										dropdownTitle="Select Network"
										name="network"
										required
										component={CurrencySelect}
										activeValue={selectedNetwork}
										// arr={selectedCoin?.chains?.filter((chain) => chain.id !== 7)}
										arr={
											selectedCoin && selectedCoin.code === 'usdc'
												? selectedCoin?.chains?.filter((chain) => chain.id !== 5)
												: selectedCoin?.chains?.filter((chain) => chain.id !== 7)
										}
										onChange={setSelectedNetwork}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
									/>
								</div>
								<Field
									title="Wallet Address"
									type="text"
									placeholder="Enter Wallet Address"
									name="address"
									required
									component={Input}
								/>

								<button
									type="submit"
									disabled={
										!(isValid && dirty && selectedCoin && selectedNetwork) ||
										isSubmitting ||
										buttonDisabled
									}
									className="btn btn-primary btn--full"
								>
									{id ? 'Update' : 'Add'} Wallet Address
								</button>
							</div>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default WalletAddressForm;
