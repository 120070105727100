import React, { FC, useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import useClickOutside from 'hooks/useClickOutside';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPopUp } from '../types';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

const GoToTrade: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const wallets = useSelector(getWalletsList);
	const trade = useSelector(getTradeData);
	const [opened, setOpened] = useState(false);
	const [selected, setSelected] = useState<IWalletItem | null>(null);

	const dropdownElement = useRef(null);
	useClickOutside(dropdownElement, () => setOpened(false));

	const toggleSelect = () => {
		setOpened(!opened);
	};

	const goToTradeHandler = () => {
		localStorage.asset = JSON.stringify({
			asset_id: selected?.asset.id,
			asset_code: selected?.asset.code,
		});
		closeModal();
		history.push('/trade');
	};

	const handleSelect = (value: IWalletItem) => {
		setSelected(value);
		setOpened(!opened);
	};

	useEffect(() => {
		if (trade) {
			// const asset = JSON.parse(localStorage.asset);
			const wallet = wallets?.find((item) => item.asset.code === trade.from_asset_code);
			setSelected(wallet || null);
		}
		/* eslint-disable */
	}, []);

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				<div className="popup-header">
					<p className="popup-header__title">Are you sure?</p>
				</div>
				<div className="popup-body">
					<div className="select-block">
						<div className="select-block__name select-block__name--type2">
							<p>Selected trade</p>
						</div>
						<div className={`select select--type2 ${opened ? 'active' : ''}`}>
							<button
								type="button"
								className="select__current select__current--placeholder"
								onClick={toggleSelect}
							>
								<span className="coin coin--type3">
									<span className="coin__icon">
										<img src={selected?.asset.img_path || ''} alt="" />
									</span>
									<span className="coin__text ">
										{selected?.asset.code.toUpperCase()}
										<span className="coin__text-more">{selected?.asset.name}</span>
									</span>
								</span>
								<span className="select__current-arrow">
									<svg
										width="10"
										height="5"
										viewBox="0 0 10 5"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M10 0H1H0L5 5L10 0Z" fill="#777E90" />
									</svg>
								</span>
							</button>
							<div className="select__drop" ref={dropdownElement}>
								<div className="select__drop-title">
									<p>Select Currency</p>
									<button type="button" onClick={() => setOpened(!opened)}>
										<svg
											width="16"
											height="17"
											viewBox="0 0 16 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M2.66699 13.8337L13.3337 3.16699"
												stroke="#292D32"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M13.3337 13.8337L2.66699 3.16699"
												stroke="#292D32"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</div>
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul>
											{wallets?.map((item, index) => {
												return (
													<li key={index}>
														<button type="button" onClick={() => handleSelect(item)}>
															<span className="coin coin--type3">
																<span className="coin__icon">
																	<img src={item.asset.img_path} alt="" />
																</span>
																<span className="coin__text ">
																	{item.asset.code.toUpperCase()} <br />
																	<span className="coin__text-more coin__text-more--type2">
																		{item?.asset.name}
																	</span>
																</span>
															</span>
														</button>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="successful-info">
						<div className="successful-info__item">
							<p>Balance:</p>
							<span>
								{toFixedNumber(
									Number(selected?.balance) + Number(selected?.frozen_balance),
									selected?.asset.code,
									true,
								)}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Available:</p>
							<span>{toFixedNumber(selected?.balance, selected?.asset.code, true)}</span>
						</div>
						<div className="successful-info__item">
							<p>Pending:</p>
							<span>{toFixedNumber(selected?.frozen_balance, selected?.asset.code, true)}</span>
						</div>
						<div className="successful-info__item">
							<p>EUR Value:</p>
							<span>
								{toFixedNumber(
									Number(selected?.balance) + Number(selected?.frozen_balance),
									selected?.asset.code,
									true,
								)}
								<strong className="strong--lighted">
									{' '}
									≈ €{toFixedNumber(selected?.balance_eur, 'eur', true)}
								</strong>
							</span>
						</div>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button
							type="button"
							className="button button--type2 button--full-width"
							onClick={closeModal}
						>
							Cancel
						</button>
						<button type="button" className="button button--full-width" onClick={goToTradeHandler}>
							Trade
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default GoToTrade;
